import React from "react"
import Layout from "../../components/layout"
import HeaderBanner from "../../components/common/headerBanner/headerBanner"
import CTABanner from "../../components/common/ctaBanner/ctaBanner"
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb"
import { VideosList } from "../../components/sections/videos-list/videos-list"

export default () => {
  return (
    <Layout>
      <HeaderBanner title="Videos" />
      <div
        style={{
          margin: "86px 0px",
          padding: "0px 20px",
        }}
      >
        <Breadcrumb
          firstLink="/white-papers-use-cases"
          firstLinkTitle="Videos"
        />
      </div>
      <VideosList />
      <CTABanner
        tagline="Step Into The Future"
        CTA="video"
        CTADetail="how video"
      />
    </Layout>
  )
}
